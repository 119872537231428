import React, { ReactNode } from "react";
import Layout from "../../components/ui/Layout";
import Column from "../../components/ui/Column";
import * as classes from "./questions-for-richard-corcoran.module.scss";
import LinkedText from "../../components/ui/LinkedText";

function Q({ question, details }: { question: string; details: ReactNode }) {
  return (
    <div>
      <div className={classes["question"]}>Q: {question}</div>
      <div className={classes["details"]}>{details}</div>
    </div>
  );
}

function Reference({
  to,
  displayText = "reference",
}: {
  to: string;
  displayText?: string;
}) {
  return (
    <LinkedText external={true} to={to}>
      {displayText}
    </LinkedText>
  );
}

function QuestionsForCorcoranPage() {
  return (
    <Layout>
      <Column>
        <div className={classes["content"]}>
          <div className={classes["header"]}>
            Questions for Richard Corcoran
          </div>
          <p>
            These questions are also available as a PDF to{" "}
            <LinkedText
              external={true}
              to="/NCFLF%20Questions%20for%20Richard%20Corcoran%204%20Mar%2024.pdf"
            >
              view, print, or download
            </LinkedText>
            .
          </p>
          <div className={classes["questionList"]}>
            <Q
              question={
                "Was hiring Taryn Fenske for $15k per month since July a good use of taxpayer money?"
              }
              details={
                <>
                  She’s listed on the My Florida Marketplace vendor bidding
                  system. Was there bidding for her procurement? (
                  <Reference to="https://vendor.myfloridamarketplace.com/" />)
                </>
              }
            />

            <Q
              question="Are the admissions videos she produced actually going to attract honors students to apply?"
              details={
                <>
                  If her marketing talents really were worth it, then why was
                  one of only two videos she produced taken down from social
                  media? Was it because the only footage of “Professors Who
                  Actually Teach” in fact showed a trans faculty member who
                  already fled the campus? (
                  <Reference
                    displayText="reference 1"
                    to="https://www.tampabay.com/news/florida-politics/2024/02/27/new-college-florida-taryn-fenske-desantis-communications-videos-public-relations/"
                  />
                  ,{" "}
                  <Reference
                    displayText="reference 2"
                    to="https://www.insidehighered.com/news/quick-takes/2023/08/18/resignations-and-hotels-chaos-continues-new-college"
                  />
                  ) Does Yale advertise like this? Does Wharton? Does Hillsdale?
                </>
              }
            />

            <Q
              question="Why was comedian Andrew Doyle paid $40,000 to teach a four-week-long Independent Study Project for four students?"
              details={
                <>
                  Another professor hired to teach the same kind of project for
                  the same amount of time was paid $6,000. (
                  <Reference to="https://drive.google.com/file/d/1Kl-vLRVXq1pjZ93kQMpjzylxrHhF-2Ad/view?usp=sharing" />
                  )
                </>
              }
            />

            <Q
              question="Have New College Foundation donors been informed how their funds are being allocated?"
              details={
                <>
                  Funds with names like “Faculty Development Endowment” and “Bob
                  Johnson Endowment for Academic Excellence” were allocated, not
                  to faculty nor academics, but to the president’s salary and
                  athletics programs. Has this been reconciled with donor
                  intent? (
                  <Reference to="https://www.heraldtribune.com/story/opinion/letters/2024/02/20/new-colleges-leaders-should-stop-hiding-and-start-explaining/72653583007/" />
                  )
                </>
              }
            />

            <Q
              question="When will the university have a 2023-2024 budget?"
              details={
                <>
                  Faculty and staff have been spending cautiously without one,
                  and the legislature has proposed limits on funding without a
                  proper plan. (
                  <Reference to="https://twitter.com/Jason_Garcia/status/1764733304538911184" />
                  ) Instead of producing a budget, the administration just fired
                  the VP of Finance and Administration. (
                  <Reference to="https://twitter.com/NCFSnowflakes/status/1764770605960777944" />
                  )
                </>
              }
            />

            <Q
              question="Why is the position of provost, the highest academic post in the university, now being filled by a lobbyist with no Ph.D and no teaching experience?"
              details={
                <>
                  In actual conservative universities, like any other institutes
                  of higher learning, the provost typically has 10+ years of
                  faculty experience. At Hillsdale, the provost has more than
                  30. (
                  <Reference to="https://www.linkedin.com/in/christopher-vanorman-7913bb79/" />
                  ) At New College, the position has just been given to the
                  still-new dean of students, who at least one conservative
                  commentator already thinks should be fired for his lapses in
                  judgment. (
                  <Reference to="https://www.wedu.org/shows/florida-this-week/" />
                  , Feb 9 program, at 18:50)
                </>
              }
            />

            <Q
              question="The new athletic department is provisionally accepted to the NAIA. Who is the faculty athletics representative?"
              details={
                <>
                  The original hand-written NAIA application had a staff person
                  sign instead of a teacher tasked with overseeing academic
                  standards. (
                  <Reference to="https://drive.google.com/file/d/1p8nRy7kmJkOK4cT223J42A6O9sZfVdI8/view?usp=sharing" />
                  ) Currently, the lobbyist dean and provost is listed as
                  interim faculty rep, despite also not being part of the
                  faculty.
                </>
              }
            />

            <Q
              question="If campus freedom is increasing, why have regulations recently changed so that administrators now control student government?"
              details={
                <>
                  Specifically, that “legislation of the student government
                  association shall be subject to the approval of the Vice
                  President for Student Affairs prior to implementation”? (
                  <Reference to="https://www.ncf.edu/wp-content/uploads/2024/02/Board-of-Trustees-Meeting-Materials-v1_Feb-22-2024.pdf" />
                  )
                </>
              }
            />

            <Q
              question="If the campus is short on dormitory space, why were orders given to demolish B-dorm, a building that had just been renovated at taxpayer expense two years previously?"
              details={
                <>
                  Now that the demo orders have been paused, why is B-dorm still
                  empty? (
                  <Reference to="https://ncfcatalyst.com/rent-hike-incoming-for-dort-gold-and-b-dorm-to-cover-water-line-and-hvac-replacement-costs/" />
                  )
                </>
              }
            />

            <Q
              question="If New College was “circling the drain” before Corcoran was named president, how did 2022 see a record increase in enrollment?"
              details={
                <>
                  That’s the year <em>before</em> he was hired… for double the
                  salary of the president responsible for that increase. (
                  <Reference
                    displayText="reference 1"
                    to="https://www.ncf.edu/news/this-fall-new-college-of-florida-welcomes-its-largest-incoming-class-since-2016/"
                  />
                  ,{" "}
                  <Reference
                    displayText="reference 2"
                    to="https://www.highereddive.com/news/new-college-of-florida-approves-corcorans-president-contract-doubling-hi/697400/"
                  />
                  )
                </>
              }
            />

            <Q
              question="If the new athletics department resulted in another boost to enrollment, what’s the net increase in the student body?"
              details={
                <>
                  The net gain in students was either 7 or 8 last semester, but
                  even more have transferred out this semester, including
                  student-athletes who decided the school was not what they’d
                  been told. (
                  <Reference
                    displayText="reference 1"
                    to="https://twitter.com/coach_bianchi/status/1749473894983934242"
                  />
                  ) … which is unsurprising, given that admissions officials had
                  been instructed to lie to them. (
                  <Reference
                    displayText="reference 2"
                    to="https://www.youtube.com/watch?v=IqNPY0pnQ2E#t=20m19s"
                  />
                  )
                </>
              }
            />

            <Q
              question="If an equally large group of student-athletes arrives next fall, will the current studentathletes be required to give up their dormitories and move to hotels?"
              details={
                <>
                  Last year, notifications that upperclassmen had to move to
                  hotels were made in some cases after they already started
                  moving into their reserved housing. (
                  <Reference to="https://www.wfla.com/news/sarasota-county/not-what-i-signed-up-for-new-college-puts-students-in-hotels-amid-mold-concerns/" />
                  )
                </>
              }
            />

            <Q
              question="Are you still receiving information or advice from Robert Allen and Bridget Ziegler?"
              details={
                <>
                  Both were on the committee that selected Corcoran as president
                  (
                  <Reference to="https://ncfcatalyst.com/new-college-presidential-search-committee-members-have-been-announced-heres-what-you-need-to-know/" />
                  ), and both have faced legal scrutiny for violations of
                  Sunshine Law (
                  <Reference to="https://www.heraldtribune.com/story/news/politics/2023/05/04/ron-desantis-office-used-new-college-florida-alum-bob-allen-as-board-liaison/70174462007/" />
                  ) and of other kinds (no reference needed).
                </>
              }
            />

            <Q
              question="If obliged to postpone his academic duties during trials for either the Jefferson County bigrigging scandal or the Elizabeth Cornell libel-for-hire case, will the university be able to prorate the president’s salary?"
              details={
                <>
                  How about the salary of New College counsel Bill Galvano, who
                  is also serving as Corcoran’s personal attorney? (
                  <Reference
                    to="https://billytownsend.substack.com/p/fake-new-college-make-federal-grand"
                    displayText="reference 1"
                  />
                  ,{" "}
                  <Reference
                    displayText="reference 2"
                    to="https://www.orlandosentinel.com/2023/10/30/gop-operatives-ordered-to-provide-records-in-engels-libel-suit/"
                  />
                  ,{" "}
                  <Reference
                    displayText="reference 3"
                    to="https://www.orlandosentinel.com/2023/11/29/new-college-spending-corcoran-galvano-maxwell/"
                  />
                  )
                </>
              }
            />

            <Q
              question="There’s a scholarship program for the Ricketts Great Books program. Is this a normal scholarship, or is it a reimbursement available only if the student finishes all classes in the program?"
              details={
                <>
                  Are incoming international students informed of these terms?
                  Currently the only two students are regular NCF students, and
                  thus ineligible for the scholarship or reimbursement. (
                  <Reference to="https://www.heraldtribune.com/story/opinion/letters/2024/02/20/new-colleges-leaders-should-stop-hiding-and-start-explaining/72653583007/" />
                  )
                </>
              }
            />

            <Q
              question="How can the university hire assistant professor Jeffrey Scarborough without a faculty search committee?"
              details={
                <>
                  This is required by the faculty handbook, as recognized by the
                  Board of Governors. The professor is ostensibly teaching for a
                  new division, the formation of which also requires a formal
                  amendment to the faculty handbook. (
                  <Reference
                    displayText="reference 1"
                    to="https://www.novocollegian.org/blog/new-college-hires-faculty-for-new-division-for-the-study-of-western-civilization"
                  />
                  ,{" "}
                  <Reference
                    displayText="reference 2"
                    to="https://www.aaup.org/sites/default/files/Faculty_Handbooks_Enforceable_Contracts_2023.pdf"
                  />
                  )
                </>
              }
            />

            <Q
              question="What qualifies a “Presidential Visiting Scholar”?"
              details={
                <>
                  Dr. Bruce Gilley (who has greater academic qualifications to
                  be provost than the current lobbyist-provost) has faced
                  scathing criticism from liberal media, but also faced serious
                  questions about his academic bona fides from the conservative
                  Cato Institute. (
                  <Reference to="https://www.cato.org/commentary/case-against-case-colonialism" />
                  ) If the aim is to create an institute dedicated to academic
                  thought free from ideology, why not name scholars the Cato
                  Institute can accept?
                </>
              }
            />

            <Q
              question="Why have homeowners in The Uplands neighborhood adjoining campus, for the first time ever, been shut out of the New College Master Plan process?"
              details={
                <>
                  As resident Don Popovich wrote to the <em>Herald-Tribune</em>,
                  “Good leaders understand that you build support and value
                  stakeholder input. We respectfully request that public input
                  and transparency be made part of the Master Plan process.” (
                  <Reference to="https://www.heraldtribune.com/story/opinion/letters/2024/02/21/the-new-new-college-has-mistreated-students-and-parents/72651757007/" />
                  , scroll down) Please note that although Master Plan hearings
                  have been held, no copies of a Master Plan have been provided,
                  making it impossible for affected residents to even know what
                  questions to ask. (
                  <Reference to="https://ncffreedom.org/%5B-%5D-blog/f/deep-dive-into-the-campus-master-planning-process" />
                  )"
                </>
              }
            />
          </div>
        </div>
      </Column>
    </Layout>
  );
}

export default QuestionsForCorcoranPage;
